import React from "react";
import {
  Nav,
  Loader,
  Card,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
  FormInput,
  FormSelect,
} from "ui-kit-ck-consultant";
import FadeIn from "react-fade-in";
import moment from "moment";
import { Row, Col } from "react-flexbox-grid";

import AuthContext from "../../../context/AuthContext";
import ContactRequest from "../../../components/general/ContactRequest";
import ShowOptions from "../../../components/statistics/statistics-selfcare/ShowOptions";

import axiosConfig from "../../../utils/axiosConfig";
import checkRights from "../../../utils/checkRights";
import { getCurrencyValue } from "../../../utils/general";

import "../../../index.css";

export default class IRSA extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accessAllowed: true,
      isLoad: false,
      isDownload: false,
      // data search
      dateStart: moment().format("YYYY-01-01"),
      dateEnd: moment().format("YYYY-MM-DD"),
      network: 1,
      group: 1,
      region: 29,
      agency: 186,
      data: [],
      sinister: "",
      code: "",
      order: null,
    };

    this.sortRef = [];

    this.ref_name = React.createRef();
    this.ref_reference = React.createRef();
    this.ref_sinister = React.createRef();
    this.ref_mission = React.createRef();
    this.ref_code = React.createRef();
    this.ref_licensePlate = React.createRef();
    this.ref_natureExpertise = React.createRef();
    this.ref_a1_r3 = React.createRef();
    this.ref_a2_r3 = React.createRef();
    this.ref_a3_r3 = React.createRef();
    this.ref_VRADE = React.createRef();
    this.ref_totalReport = React.createRef();
    this.ref_SRGC = React.createRef();
    this.ref_honorary = React.createRef();
    this.ref_final = React.createRef();
  }

  static contextType = AuthContext;

  handleRadioChange = (e) => {
    let { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      this.getGeneralData
    );
  };

  componentDidMount() {
    this.setState(
      {
        accessAllowed: checkRights("StatisticsIRSA", this.context),
      },
      () => this.getData()
    );
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;

    let dateStart = this.state.dateStart;
    let dateEnd = this.state.dateEnd;

    if (
      name === "dateEnd" &&
      moment(value).format("YYYY") !== moment(dateStart).format("YYYY")
    ) {
      dateStart = moment(`${moment(value).format("YYYY")}-01-01`).format(
        "YYYY-MM-DD"
      );
    } else if (
      name === "dateStart" &&
      moment(value).format("YYYY") !== moment(dateEnd).format("YYYY")
    ) {
      dateEnd = moment(`${moment(value).format("YYYY")}-12-31`).format(
        "YYYY-MM-DD"
      );
    }

    this.setState({
      dateStart,
      dateEnd,
      [name]: value,
    });
  };

  getData = () => {
    this.setState({ isLoad: true }, () => {
      axiosConfig("pilotage-cabinet/specialties/irsa-maif/get-data", {
        data: {
          dateStart: this.state.dateStart,
          dateEnd: this.state.dateEnd,
          network: this.state.network,
          group: this.state.group,
          region: this.state.region,
          agency: this.state.agency,
        },
      })
        .then((res) => {
          if (res.data.success) {
            this.setState({
              data: res.data.data,
              isLoad: false,
            });
          } else {
            this.setState({
              data: [],
              isLoad: false,
            });
          }
        })
        .catch((err) => {
          this.setState({ isLoad: false });
        });
    });
  };

  handleSortClick = (desc, column) => {
    if (column !== 0) {
      // this.azerty.current.setState({ desc: false, sort: false });
    }
    if (column !== 1) {
      // this.azerty.current.setState({ desc: false, sort: false });
    }
    if (column !== 2) {
      this.ref_name.current.setState({ desc: false, sort: false });
    }
    if (column !== 3) {
      this.ref_reference.current.setState({ desc: false, sort: false });
    }
    if (column !== 4) {
      this.ref_sinister.current.setState({ desc: false, sort: false });
    }
    if (column !== 5) {
      this.ref_mission.current.setState({ desc: false, sort: false });
    }
    if (column !== 6) {
      this.ref_code.current.setState({ desc: false, sort: false });
    }
    if (column !== 7) {
      this.ref_licensePlate.current.setState({ desc: false, sort: false });
    }
    if (column !== 8) {
      this.ref_natureExpertise.current.setState({ desc: false, sort: false });
    }
    if (column !== 9) {
      this.ref_a1_r3.current.setState({ desc: false, sort: false });
    }
    if (column !== 10) {
      this.ref_a2_r3.current.setState({ desc: false, sort: false });
    }
    if (column !== 11) {
      this.ref_a3_r3.current.setState({
        desc: false,
        sort: false,
      });
    }
    if (column !== 12) {
      this.ref_VRADE.current.setState({
        desc: false,
        sort: false,
      });
    }
    if (column !== 13) {
      this.ref_totalReport.current.setState({ desc: false, sort: false });
    }
    if (column !== 14) {
      this.ref_SRGC.current.setState({ desc: false, sort: false });
    }
    if (column !== 15) {
      this.ref_honorary.current.setState({ desc: false, sort: false });
    }
    if (column !== 16) {
      this.ref_final.current.setState({ desc: false, sort: false });
    }

    let order = null;
    switch (column) {
      case 0:
        order = { name: "", desc: desc };
        break;
      case 1:
        order = { name: "", desc: desc };
        break;
      case 2:
        order = { name: "name", desc: desc };
        break;
      case 3:
        order = { name: "reference", desc: desc };
        break;
      case 4:
        order = { name: "sinister", desc: desc };
        break;
      case 5:
        order = { name: "mission", desc: desc };
        break;
      case 6:
        order = { name: "code", desc: desc };
        break;
      case 7:
        order = { name: "licensePlate", desc: desc };
        break;
      case 8:
        order = { name: "natureExpertise", desc: desc };
        break;
      case 9:
        order = { name: "a1_r3", desc: desc };
        break;
      case 10:
        order = { name: "a2_r3", desc: desc };
        break;
      case 11:
        order = { name: "a3_r3", desc: desc };
        break;
      case 12:
        order = { name: "VRADE", desc: desc };
        break;
      case 13:
        order = { name: "totalReport", desc: desc };
        break;
      case 14:
        order = { name: "SRGC", desc: desc };
        break;
      case 15:
        order = { name: "honorary", desc: desc };
        break;
      case 16:
        order = { name: "final", desc: desc };
        break;
      default:
        order = { name: "sinister", desc: desc };
        break;
    }
    this.setState({ order: order }, () => {
      // this.props.onSortClick(order);
    });
  };

  render() {
    let codesUniques = [];

    let codes = this.state.data.filter((folder) => {
      if (!codesUniques.includes(folder.code)) {
        codesUniques.push(folder.code);
        return true;
      }
      return false;
    });

    if (this.context.id) {
      return !this.state.accessAllowed ? (
        <FadeIn>
          <Nav title="Statistiques IRSA" className="mb-20" />
          <ContactRequest page="Statistiques - IRSA" />
        </FadeIn>
      ) : (
        <>
          <FadeIn>
            <Nav title="Statistiques IRSA" className="mb-20" />
          </FadeIn>
          <ShowOptions
            handleInputChange={this.handleInputChange}
            handleSelectChange={this.handleInputChange}
            getData={this.getData}
            isDateStart={true}
            isDateEnd={true}
            dateStart={this.state.dateStart}
            dateEnd={this.state.dateEnd}
            network={this.state.network}
            group={this.state.group}
            region={this.state.region}
            agency={this.state.agency}
            className={"mb-10 mb-20-xl"}
            dateStartText="Date de début"
            dateEndText="Date de fin"
            footerData={
              <Row>
                <Col xs={12} md={4} lg={3}>
                  <FormInput
                    title="N° sinistre"
                    name="sinister"
                    value={this.state.sinister}
                    onChange={(e) =>
                      this.setState({ sinister: e.target.value })
                    }
                  />
                </Col>

                <Col xs={12} md={4} lg={3}>
                  <FormSelect
                    title="État"
                    name="code"
                    value={this.state.code}
                    ignore={true}
                    options={[
                      { value: "", text: "Filtrage par état" },
                      ...codes.map((code) => ({
                        value: code.code,
                        text: code.code,
                      })),
                    ]}
                    onChange={(e) => this.setState({ code: e.target.value })}
                  />
                </Col>
              </Row>
            }
          />
          <Card title="Tableau" className="overflow-x-auto">
            <Table
              isCSV
              getAllData={(callback) =>
                this.setState({ isDownload: true }, callback)
              }
              csvCallback={() => this.setState({ isDownload: false })}
            >
              <Thead>
                <Tr>
                  <Th hide></Th>
                  <Th hide></Th>
                  <Th hide></Th>
                  <Th hide></Th>
                  <Th hide></Th>
                  <Th hide></Th>
                  <Th hide></Th>
                  <Th hide></Th>
                  <Th hide></Th>
                  <Th
                    colSpan={this.state.isDownload ? 1 : 3}
                    style={{
                      borderRadius: "6px",
                    }}
                  >
                    Montant objet de la réclamation Vrade ou Réparation
                  </Th>
                  {this.state.isDownload ? <Th hide></Th> : null}
                  {this.state.isDownload ? <Th hide></Th> : null}
                  <Th
                    colSpan={this.state.isDownload ? 1 : 3}
                    style={{
                      borderRadius: "6px",
                    }}
                  >
                    Vos conclusions
                  </Th>
                  {this.state.isDownload ? <Th hide></Th> : null}
                  {this.state.isDownload ? <Th hide></Th> : null}
                  <Th hide></Th>
                  <Th hide></Th>
                </Tr>
                <Tr>
                  <Th onSort={(desc) => this.handleSortClick(desc, 0)}>#</Th>
                  <Th onSort={(desc) => this.handleSortClick(desc, 1)}>
                    Nom du groupement
                  </Th>
                  <Th
                    ref={this.ref_name}
                    sort
                    onSort={(desc) => this.handleSortClick(desc, 2)}
                  >
                    Nom Expert
                  </Th>
                  <Th
                    ref={this.ref_reference}
                    sort
                    onSort={(desc) => this.handleSortClick(desc, 3)}
                  >
                    N° dossier Expert
                  </Th>
                  <Th
                    ref={this.ref_sinister}
                    sort
                    onSort={(desc) => this.handleSortClick(desc, 4)}
                  >
                    N° sinistre MAIF
                  </Th>
                  <Th
                    ref={this.ref_mission}
                    sort
                    onSort={(desc) => this.handleSortClick(desc, 5)}
                  >
                    Date de réception mission
                  </Th>
                  <Th
                    ref={this.ref_code}
                    sort
                    onSort={(desc) => this.handleSortClick(desc, 6)}
                  >
                    Etat du dossier
                  </Th>
                  <Th
                    ref={this.ref_licensePlate}
                    sort
                    onSort={(desc) => this.handleSortClick(desc, 7)}
                  >
                    Immatriculation véhicule sur la mission
                  </Th>
                  <Th
                    ref={this.ref_natureExpertise}
                    sort
                    onSort={(desc) => this.handleSortClick(desc, 8)}
                  >
                    Nature de l'expertise
                  </Th>
                  <Th
                    ref={this.ref_a1_r3}
                    sort
                    onSort={(desc) => this.handleSortClick(desc, 9)}
                  >
                    Montant VRADE partie adverse ht
                  </Th>
                  <Th
                    ref={this.ref_a2_r3}
                    sort
                    onSort={(desc) => this.handleSortClick(desc, 10)}
                  >
                    Montant REPARATION partie adverse ht
                  </Th>
                  <Th
                    ref={this.ref_a3_r3}
                    sort
                    onSort={(desc) => this.handleSortClick(desc, 11)}
                  >
                    SRGC Partie adverse ht
                  </Th>
                  <Th
                    ref={this.ref_VRADE}
                    sort
                    onSort={(desc) => this.handleSortClick(desc, 12)}
                  >
                    Montant VRADE Expert MAIF ht
                  </Th>
                  <Th
                    ref={this.ref_totalReport}
                    sort
                    onSort={(desc) => this.handleSortClick(desc, 13)}
                  >
                    Montant REPARATION Expert MAIF ht
                  </Th>
                  <Th
                    ref={this.ref_SRGC}
                    sort
                    onSort={(desc) => this.handleSortClick(desc, 14)}
                  >
                    "SRGC* Expert MAIF ht *concernés par l'assiette recours"
                  </Th>
                  <Th
                    ref={this.ref_honorary}
                    sort
                    onSort={(desc) => this.handleSortClick(desc, 15)}
                  >
                    Montant de l'honoraire
                  </Th>
                  <Th
                    ref={this.ref_final}
                    sort
                    onSort={(desc) => this.handleSortClick(desc, 16)}
                  >
                    Ecart final Global (base Recours - Honoraires)
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {this.state.data
                  .filter(
                    (folder) =>
                      (!this.state.sinister ||
                        (folder.sinister || "")
                          .toUpperCase()
                          .includes(this.state.sinister.toUpperCase())) &&
                      (!this.state.code || this.state.code === folder.code)
                  )
                  .sort((a, b) => {
                    if (!this.state.order) return 0;

                    const { name, desc } = this.state.order;
                    const valA = a[name];
                    const valB = b[name];

                    // Gestion des nombres (float ou integer)
                    if (
                      (parseFloat(valA) || parseFloat(valA) === 0) &&
                      (parseFloat(valB) || parseFloat(valB) === 0)
                    ) {
                      const numA = parseFloat(valA);
                      const numB = parseFloat(valB);

                      return desc ? numB - numA : numA - numB;
                    }

                    // Gestion des chaînes
                    if (typeof valA === "string" && typeof valB === "string") {
                      if (name.includes("mission")) {
                        // Cas spécifique des dates
                        const dateA = moment(valA, "YYYYMMDD");
                        const dateB = moment(valB, "YYYYMMDD");

                        if (dateA.isValid() && dateB.isValid()) {
                          return desc ? dateB.diff(dateA) : dateA.diff(dateB);
                        }
                      }

                      // Comparaison classique pour les autres chaînes
                      return desc
                        ? valB.localeCompare(valA)
                        : valA.localeCompare(valB);
                    }

                    return 0; // Fallback
                  })
                  .map((folder, idx) => (
                    <Tr key={idx}>
                      <Th>{idx + 1}</Th>
                      <Td>GROUPE LANG</Td>
                      <Td>{folder.name}</Td>
                      <Td>{folder.reference}</Td>
                      <Td>{folder.sinister}</Td>
                      <Td>
                        {moment(folder.mission, "YYYYMMDD").format(
                          "DD/MM/YYYY"
                        )}
                      </Td>
                      <Td>{folder.code}</Td>
                      <Td>{folder.licensePlate}</Td>
                      <Td>{folder.natureExpertise}</Td>
                      <Td>{getCurrencyValue(folder.a1_r3)}</Td>
                      <Td>{getCurrencyValue(folder.a2_r3)}</Td>
                      <Td>{getCurrencyValue(folder.a3_r3)}</Td>
                      <Td>{getCurrencyValue(folder.VRADE)}</Td>
                      <Td>{getCurrencyValue(folder.totalReport)}</Td>
                      <Td>{getCurrencyValue(folder.SRGC)}</Td>
                      <Td>{getCurrencyValue(folder.honorary)}</Td>
                      <Td>{getCurrencyValue(folder.final)}</Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </Card>
          <Loader show={this.state.isLoad || this.state.isDownload} />
        </>
      );
    } else {
      return <></>;
    }
  }
}
